'use strict'

import { mapState, mapActions } from 'vuex'
import Cookie from 'utils/Cookie'
import { isObject } from 'utils/functions'
import {
  SESSION_CURRENT_STEP,
  SESSION_CREDIT_CARD_ATTEMPTS,
  SESSION_PROSPECT,
  SESSION_PROSPECT_EXPIRES
} from 'utils/constants'

const persistProspect = {
  computed: {
    ...mapState({
      prospect: ({ prospect }) => prospect
    })
  },

  methods: {
    ...mapActions({
      setProspect: 'prospect/set',
      clearProspect: 'prospect/clear'
    }),

    removeEmptyValuesOnTheObject (obj) {
      return Object.keys(obj).reduce((acc, key) => {
        const val = obj[key]

        if (val || typeof val === 'boolean') {
          acc[key] = val
        }

        return acc
      }, {})
    },

    getProspect () {
      const currentStep = Cookie.get(SESSION_CURRENT_STEP) || 1
      const creditCardAttempts = Cookie.get(SESSION_CREDIT_CARD_ATTEMPTS) || 0
      const data = JSON.parse(Cookie.get(SESSION_PROSPECT)) || {}

      return {
        currentStep,
        creditCardAttempts,
        data
      }
    },

    createCookie (currentStep, creditCardAttempts, prospectPayload) {
      Cookie.set(SESSION_CURRENT_STEP, currentStep, SESSION_PROSPECT_EXPIRES)
      Cookie.set(SESSION_CREDIT_CARD_ATTEMPTS, creditCardAttempts, SESSION_PROSPECT_EXPIRES)
      Cookie.set(SESSION_PROSPECT, JSON.stringify(prospectPayload), SESSION_PROSPECT_EXPIRES)
    },

    saveOnStore (currentStep, creditCardAttempts, prospectPayload) {
      this.setProspect({
        currentStep,
        creditCardAttempts,
        data: prospectPayload
      })
    },

    saveProspect (payload) {
      const prospect = this.getProspect()
      const currentStep = Number(payload.currentStep || prospect.currentStep)
      const creditCardAttempts = Number(payload.creditCardAttempts || prospect.creditCardAttempts)
      const data = isObject(payload.data) ? this.removeEmptyValuesOnTheObject(payload.data) : {}
      const prospectPayload = {
        ...prospect.data,
        ...data
      }

      this.createCookie(currentStep, creditCardAttempts, prospectPayload)
      this.saveOnStore(currentStep, creditCardAttempts, prospectPayload)
    },

    removeProspect () {
      Cookie.set(SESSION_CURRENT_STEP, '', 0)
      Cookie.set(SESSION_CREDIT_CARD_ATTEMPTS, '', 0)
      Cookie.set(SESSION_PROSPECT, '', 0)
      this.clearProspect()
    }
  }
}

export default persistProspect
