'use strict'

export function isObject (val) {
  return Boolean(val && typeof val === 'object' && Object.keys(val).length)
}

export function isArray (val) {
  return Boolean(val && Array.isArray(val) && val.length)
}
export function generateRand (min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export function consoleInfo (msg) {
  if (console && console.info) {
    console.info(msg)
  }
}
